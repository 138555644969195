import { PropsWithChildren, ReactNode } from 'react';

import { Box } from 'components/Box';
import { Icon } from 'components/Icon/Icon';
import { LegacyButton } from 'components/LegacyButton/LegacyButton';
import { BodySmall } from 'components/Text/BodySmall';
import { DefaultTooltipButton } from 'components/Tooltip/DefaultTooltipButton';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { colors } from 'theme/theme';

import { SearchFilterSectionFiltersBox } from './SearchFilterSectionFiltersBox';
import { SearchFilterSectionTileBox } from './SearchFilterSectionTileBox';

type Props = {
  'data-qa-id'?: string;
  'data-qa-filter-value'?: string;
  title: string;
  header?: ReactNode;
  footer?: ReactNode;
  noMask?: boolean;
  noToggle?: boolean;
  noTopBorder?: boolean;
  count?: number;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggleExpanded?: (...args: Array<any>) => any;
  expanded?: boolean;
  hasOptionFilter?: boolean;
  tooltip?: ReactNode;
};

export function SearchFilterSection({
  title,
  header,
  footer,
  children,
  noToggle,
  noTopBorder,
  count,
  toggleExpanded,
  expanded,
  noMask,
  hasOptionFilter,
  tooltip,
  ...props
}: PropsWithChildren<Props>) {
  return (
    <Box
      mt="-1px"
      borderBottom={
        noToggle
          ? undefined
          : [
              `1px solid ${colors.selectionGrey}`,
              null,
              null,
              expanded ? '1px solid transparent' : null,
            ]
      }
      borderTop={[
        `1px solid ${colors.selectionGrey}`,
        null,
        null,
        expanded ? '1px solid transparent' : null,
      ]}
      className={expanded ? 'is-expanded' : undefined}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <SearchFilterSectionTileBox $expanded={expanded} noMask={noMask}>
        {!noToggle && (
          <LegacyButton
            onClick={noToggle ? undefined : toggleExpanded}
            width="100%"
            tabIndex={noToggle ? -1 : undefined}
          >
            <Box m={0} data-qa-id="section-heading">
              <Box
                display="flex"
                alignItems="center"
                // @ts-expect-error TS(2322): Type 'string | false | undefined' is not assignabl... Remove this comment to see the full error message
                color={expanded && colors.brandBlue}
              >
                <Box display="flex" alignItems="center">
                  <BodySmall>{title}</BodySmall>
                  {tooltip && (
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    <Box ml="6px" onClick={(e: any) => e.stopPropagation()}>
                      <Tooltip
                        placement="top-left"
                        content={tooltip}
                        size="large"
                      >
                        <DefaultTooltipButton
                          // Landing pages are SSRd.
                          //
                          // Using the tooltip as a button creates an invalid DOM
                          // with a button inside a button, which then causes
                          // a React mismatch error, duplicating elements on the screen
                          as="span"
                        />
                      </Tooltip>
                    </Box>
                  )}
                </Box>
                {noToggle || (
                  <Box ml="auto">
                    {typeof count === 'number' && count > 0 && (
                      <Box display="inline-block" mr={24}>
                        <BodySmall color={colors.brandBlue}>{count}</BodySmall>
                      </Box>
                    )}
                    <Icon
                      name={expanded ? 'chevron-up' : 'chevron-down'}
                      colorName="brandBlue"
                      size={14}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </LegacyButton>
        )}
        <Box
          display={expanded || noToggle ? 'block' : 'none'}
          mt={noToggle ? 0 : 16}
          pt={!header ? 0 : 24}
          borderTop={noToggle ? undefined : `1px solid ${colors.selectionGrey}`}
        >
          {header}
          <Box position="relative">
            <SearchFilterSectionFiltersBox
              data-qa-id="search-filter-section-filters"
              $noMask={noMask || noToggle}
              maxHeight={[
                null,
                null,
                null,
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-nested-ternary
                noMask || noToggle ? null : hasOptionFilter ? 324 : 302,
              ]}
            >
              {children}
            </SearchFilterSectionFiltersBox>
          </Box>
          {footer}
        </Box>
      </SearchFilterSectionTileBox>
    </Box>
  );
}
